import React from "react";
import { Link } from "react-router-dom";
const PrivacyPolicyPage = () => {
  return (
    <div className="container mb-3">
      <div className="row justify-content-center">
        <div className="col-12 col-xl-8">
          <h1 className="mt-5 mb-3 fs-4">
            POLITYKA PRYWATNOŚCI W FIRMIE BIKE MULTI SPORT MAREK GĄSIOR
          </h1>

          <p className="text-justify fw-bold">Cześć Bajkerko! Cześć Bajkerze!</p>
          <p className="text-justify">
            Jeżeli korzystasz ze stron internetowych:{" "}
            <a href="http://bmsbike.com/">http://bmsbike.com/</a>,{" "}
            <a href="http://bmsrent.pl/">http://bmsrent.pl/</a> („Strony
            Internetowe BMS”) zachęcamy gorąco, abyś zapoznał się z naszą
            Polityką Prywatności (PP).
          </p>
          <p className="text-justify">
            Gdy przeczytasz PP, zyskasz pewność, że Twoje dane osobowe, które
            nam powierzasz zbieramy, przechowujemy i używamy z niezwykłą
            starannością i troską. Przestaniesz zastanawiać się: dlaczego i do
            czego ich używamy. Zamiast łamać sobie głowę, w jaki sposób je
            zabezpieczamy, zaczniesz spokojnie kręcić kilometry. Nasze
            obowiązki, Twoje prawa oraz zagadnienia związane z plikami cookies
            będą dla Ciebie jasne, jak blask lakieru nowej ramy.
          </p>

          <h2 className="fs-5">I. Kto administruje Twoje dane osobowe?</h2>
          <ol>
            <li>
              Administratorem strony bmsbike.com oraz administratorem Twoich
              danych osobowych jest Marek Gąsior prowadzący działalność
              gospodarczą pod firmą Bike Multi Sport Marek Gąsior, ul.
              Rzemieślnicza 6, 72-100 Goleniów, REGON 812576007, NIP 8561025734,
              wpisaną do Centralnej Ewidencji i Informacji o Działalności
              Gospodarczej.
            </li>
            <li>
              Jeżeli masz jakiekolwiek wątpliwości i pytania dotyczące zasad
              ochrony Twoich danych osobowych w naszej firmie, napisz na nasz
              adres mailowy lub zadzwoń.
            </li>
          </ol>

          <h2 className="fs-5">
            II. W jaki sposób zbieramy Twoje dane osobowe?
          </h2>
          <ol>
            <li>
              Automatycznie: gdy odwiedzasz Strony Internetowe BMS,
              automatycznie zbierane są Twoje dane informatyczne dotyczące
              Twoich odwiedzin, w szczególności:
              <ol type="a">
                <li>adres IP,</li>
                <li>rodzaj systemu operacyjnego,</li>
                <li>rodzaj/typ przeglądarki.</li>
              </ol>
            </li>
            <li>
              Dobrowolnie przekazujesz nam swoje dane osobowe, gdy:
              <ol type="a">
                <li>zapisujesz się do Newslettera,</li>
                <li>składasz zamówienie,</li>
                <li>rezerwujesz, wypożyczasz rower lub akcesoria rowerowe,</li>
                <li>
                  kontaktujesz się z nami w sprawie swojego zamówienia,
                  rezerwacji lub innej związanej z działalnością Stron
                  Internetowych BMS.
                </li>
              </ol>
            </li>
            <li>
              Dane osobowe możesz nam przekazać za pomocą telefonu, poczty
              e-mail, faksu, formularzy, itp. Zbierzemy od Ciebie wyłącznie te
              dane, które są konieczne do prawidłowego wykonania świadczonej
              przez nas usługi:
              <ol type="a">
                <li>imię i nazwisko,</li>
                <li>adres e-mail,</li>
                <li>nr telefonu,</li>
                <li>adres zamieszkania,</li>
                <li>
                  nr PESEL (wyłącznie w przypadku wypożyczania przez Ciebie
                  roweru) numery dwóch dowodów tożsamości (wyłącznie w przypadku
                  wypożyczania przez Ciebie roweru),
                </li>
                <li>
                  przedział wagowy (wyłącznie podczas rezerwacji roweru na{" "}
                  <a href="http://bmsrent.pl">http://bmsrent.pl</a>),
                </li>
                <li>
                  dodatkowe dane: informacje o prowadzonej działalności
                  gospodarczej, jeżeli chcesz otrzymać fakturę w związku z
                  prowadzoną działalnością gospodarczą,
                </li>
                <li>
                  numer konta bankowego - wymagany tylko, gdy będziesz zwracał
                  lub reklamował towar.
                </li>
              </ol>
            </li>
            <li>
              Podanie przez Ciebie danych osobowych jest zawsze dobrowolne.
              Jednak jest niezbędne, abyś złożył zamówienie, zapisał się do
              Newslettera, zarezerwował i wypożyczył rower, akcesoria, lub
              skontaktował się z nami.
            </li>
            <li>
              Zapewniamy, że Twoje dane osobowe gromadzimy z należytą
              starannością i odpowiednio chronimy przed dostępem do nich osób
              nieupoważnionych. Gwarantujemy poufność i podjęcie wszelkich
              środków bezpieczeństwa i ochrony danych osobowych, których wymaga
              prawo RODO. Twoje dane osobowe przetwarzamy wyłącznie na
              terytorium Unii Europejskiej.
            </li>
          </ol>

          <h2 className="fs-5 my-3">
            III. W jakim celu zbieramy i przetwarzamy Twoje dane osobowe? Jak
            długo je przechowujemy? Jakie podstawy prawne nam to umożliwiają?
          </h2>
          <h3 className="fs-6 fw-bold">
            1. Zamówienia, rezerwacje, wypożyczenie roweru.
          </h3>

          <p className="text-justify">
            Gdy składasz zamówienie, musisz nam podać swoje dane osobowe
            niezbędne do realizacji zamówienia: imię i nazwisko, adres
            zamieszkania, adres e-mail, numer telefonu. Podczas rezerwacji
            możesz nam podać również swój przedział wagowy. Umożliwi nam to
            odpowiednie ustawienie amortyzatorów. Wypożyczając rower podajesz
            nam numery dwóch dowodów swojej tożsamości oraz nr PESEL. Możesz
            podać też dodatkowe dane dotyczące prowadzonej przez Ciebie
            działalności gospodarczej. Podstawą prawną do przetwarzania Twoich
            danych osobowych zawartych w zamówieniu jest Twoja zgoda na
            wykonanie umowy o świadczenie usług lub umowy najmu roweru. Podane
            przez Ciebie dane zostaną zapisane w bazie i będą w niej
            przechowywane do czasu przedawnienia roszczeń z tytułu zawartych
            umów. Każde zamówienie odnotowujemy jako odrębną pozycję w bazie.
            Oprócz danych osobowych w bazie przechowujemy również szczegóły
            zamówienia: datę złożenia zamówienia, przedmiot i wartość
            zamówienia.
          </p>
          <h3 className="fs-6 fw-bold">2. Newsletter</h3>
          <p className="text-justify">
            Za pośrednictwem strony{" "}
            <a href="http://bmsbike.com">http://bmsbike.com</a> możesz się
            zapisać do naszego Newslettera. Wówczas otrzymasz informacje o
            nowościach, promocjach, wyprzedażach, aktualnościach, produktach i
            usługach związanych z naszą aktywnością w Internecie. W celu
            przesłania Newslettera potrzebujemy wyłącznie Twojego adresu e-mail.
            Dodatkowo możesz też podać nam swoje imię. Podstawą prawną
            przetwarzania Twoich danych osobowych w przypadku Newslettera jest
            Twoja zgoda wyrażona poprzez kliknięcie w link aktywacyjny.
            Otrzymujesz go pocztą e-mail po zapisaniu się do Newslettera. Dane
            przetwarzane są przez czas funkcjonowania Newslettera. W każdej
            chwili możesz zrezygnować z subskrypcji Newslettera, klikając link
            anulujący subskrypcję, znajdujący się w stopce każdego Newslettera.
            Twoje dane nie są przez nas wykorzystywane do podejmowania decyzji
            opartych na zautomatyzowanym przetwarzaniu danych, które mogłyby
            mieć wpływ na Twoją sytuację prawną lub wywołać dla Ciebie inne
            podobne skutki. Nowoczesny system e-mail marketingu pozwala
            natomiast analizować działania odbiorców po otrzymaniu od nas
            wiadomości. Robimy to, by lepiej dostosować treść Newslettera do
            Twoich zainteresowań. W każdej chwili możesz sprzeciwić się takiemu
            przetwarzaniu danych.
          </p>

          <h3 className="fs-6 fw-bold">3. Poczta elektroniczna.</h3>
          <p className="text-justify">
            Gdy kontaktujesz się z nami poprzez pocztę elektroniczną, w sposób
            naturalny przekazujesz nam swój adres e-mail, jako adres nadawcy
            wiadomości. Inne dane osobowe możesz przekazać nam również w treści
            wiadomości. Podstawą prawną przetwarzania danych osobowych w takim
            przypadku jest Twoja zgoda wynikająca z zainicjowania z nami
            kontaktu. Dane osobowe, które przekazujesz nam pocztą elektroniczną
            przetwarzane są wyłącznie w celu obsługi Twojego zapytania. Treść
            korespondencji może podlegać archiwizacji.
          </p>

          <h3 className="fs-6 fw-bold">4. Formularze</h3>
          <p className="text-justify">
            Kiedy chcesz zwrócić, wymienić towar lub zgłosić reklamację
            wypełniasz odpowiednie formularze dostępne na naszej stronie{" "}
            <a href="http://bmsbike.com">http://bmsbike.com</a>. Wypełniając je
            przekazujesz nam swoje imię i nazwisko, adres zamieszkania, adres
            e-mail, numer telefonu. Dodatkowo w formularzu zwrotu lub reklamacji
            towaru podajesz numer konta bankowego, na który wysyłamy pieniądze
            za zwrócony towar lub poniesiony przez Ciebie koszt przesyłki w
            przypadku uznania reklamacji. Podstawą prawną do przetwarzania
            Twoich danych osobowych zawartych w formularzach jest Twoja zgoda na
            wykonanie umowy o świadczenie usług. Podane przez Ciebie dane
            zostaną zapisane w bazie i będą w niej przechowywane do czasu
            przedawnienia roszczeń z tytułu zawartej umowy.
          </p>

          <h2 className="fs-5 my-3">
            IV. Jak długo będziemy przetwarzać Twoje dane osobowe?
          </h2>
          <ol>
            <li>
              Twoje dane przetwarzamy:
              <ol type="a">
                <li>
                  do czasu zakończenia naszej korespondencji w danej sprawie,
                </li>
                <li>do czasu wykonania zawartych pomiędzy nami umów,</li>
                <li>do czasu przedawnienia roszczeń z tego wynikających,</li>
                <li>
                  do czasu, kiedy nie wycofasz udzielonej nam zgody na
                  Newsletter.
                </li>
              </ol>
            </li>
          </ol>
          <h2 className="fs-5 my-3">V. Komu przekazujemy Twoje dane?</h2>
          <ol>
            <li>
              Twoje dane przekazujemy:
              <ol type="a">
                <li>
                  firmie księgowej do celów rozliczeń, jeśli jesteś naszym
                  klientem,
                </li>
                <li>firmom kurierskim, jeżeli jesteś naszym klientem,</li>
                <li>
                  firmie Ido-Sell Shop obsługującej nasze Strony Internetowe
                  BMS,
                </li>
                <li>
                  Ceneo.pl przekazujemy Twój adres e-mail, tylko wówczas gdy
                  wyraziłeś na to zgodę, w celu zbadania opinii o poziomie
                  zadowolenia z zakupów w naszym sklepie.
                </li>
              </ol>
            </li>
          </ol>
          <h2 className="fs-5 my-3">
            VI. Jak zbierane są dane osobowe w sposób automatyczny? Co to są
            pliki cookies?
          </h2>
          <ol>
            <li>
              Przy okazji Twoich odwiedzin naszej Strony Internetowej BMS w
              sposób automatyczny zbieramy niektóre Twoje dane. Dane te są
              zbierane przez tzw. pliki cookies („Ciasteczka”). Ciasteczka to
              pliki wysyłane do Twojego komputera lub innego urządzenia podczas
              przeglądania Stron Internetowych BMS.
            </li>
            <li>
              Cookies są niewielkimi informacjami, przechowywanymi na Twoim
              urządzeniu końcowym (np. komputerze, tablecie, smartfonie, itp),
              które mogą być odczytywane przez nasz system teleinformatyczny.
            </li>
            <li>
              Ciasteczka pozwalają nam na zapamiętanie i sprawdzenie Twoich
              preferencji. Dzięki temu możemy m.in. poprawiać Twoje wyniki
              wyszukiwania, dbać o trafność wyświetlanych przez Ciebie
              informacji.
            </li>
            <li>
              Cookies umożliwiają nam:
              <ol type="a">
                <li>
                  zapewnić prawidłowe funkcjonowanie strony, jakiego oczekujesz,
                </li>
                <li>
                  poprawić szybkość i bezpieczeństwo korzystania ze strony,
                </li>
                <li>ulepszać funkcje dostępne dla Ciebie na naszej stronie,</li>
                <li>korzystać z narzędzi analitycznych,</li>
                <li>korzystać z narzędzi marketingowych,</li>
                <li>korzystać z narzędzi marketingowych,</li>
                <li>zapewniać funkcje społecznościowe</li>
              </ol>
            </li>
            <li>
              Ciasteczka nie dokonują żadnych modyfikacji czy zmian ustawień w
              urządzeniu lub oprogramowaniu, które zostało zainstalowane na
              Twoim urządzeniu.
            </li>
            <li>
              Masz prawo nie wyrazić zgody na wykorzystywanie przez nas cookies,
              możesz je blokować. Jeżeli chcesz blokować Ciasteczka,
              rekomendujemy wybranie odpowiednich ustawień w Twojej przeglądarce
              www. Więcej informacji na ten temat znajdziesz np. na stronie{" "}
              <a href="http://wszystkoociasteczkach.pl">
                http://wszystkoociasteczkach.pl
              </a>
            </li>
            <li>
              Korzystanie przez Ciebie ze Stron Internetowych BMS oznacza
              wyrażenie zgody na wykorzystywanie przez nas Ciasteczek. Komunikat
              na ten temat wyświetla się automatycznie, zawsze przy pierwszej
              wizycie użytkownika na Stronach Internetowych BMS. Zablokowanie
              cookies lub ich usunięcie może spowodować utrudnienia w
              korzystaniu ze Stron Internetowych BMS, a niektóre opcje mogą
              zostać usunięte.
            </li>
            <li>
              Dane zbierane automatycznie przy pomocy Ciasteczek będą przez nas
              używane do analizy zachowań użytkowników na Stronach Internetowych
              BMS i zbierania danych demograficznych o użytkownikach. Pozwala
              nam to dostosować treści stron internetowych oraz wyświetlać
              treści dotyczące naszych produktów na innych stronach
              internetowych, które odwiedzasz (więcej na temat innych
              technologii stosowanych na Stronach Internetowych BMS przeczytasz
              poniżej). Jeżeli nie wyrażasz zgody na taką personalizację treści,
              zmień ustawienia swojej przeglądarki, (tak, jak wskazaliśmy
              wcześniej). Zawsze możesz do nas napisać:{" "}
              <a href="mailto:bok@bmsbike.com">bok@bmsbike.com</a>
            </li>
          </ol>
          <h2 className="fs-5 my-3">
            VII. Cookies podmiotów trzecich. Jakie inne technologie stosujemy na
            Stronach Internetowych BMS?
          </h2>
          <ol>
            <li className="fs-6 fw-bold">
              Analizy statystyczne Stron Internetowych BMS:
            </li>
            <p className="mt-3">
              Wykorzystujemy pliki cookies do śledzenia statystyk Stron
              Internetowych BMS, takich jak: liczba osób odwiedzających, rodzaj
              systemu operacyjnego i przeglądarki internetowej wykorzystywanej
              do przeglądania Stron Internetowych BMS, czas spędzony na Stronach
              Internetowych BMS, odwiedzane podstrony itp.
            </p>
            <p>
              Korzystamy w tym zakresie z narzędzi Google Analytics. Zbierane w
              tym zakresie informacje są całkowicie anonimowe i nie pozwalają na
              Twoją identyfikację. W tym celu wykorzystywane są pliki cookies
              firmy Google LLC dotyczące usługi Google Analytics.
            </p>
            <p>
              Korzystanie z usług Google Analytics związane jest z
              zaimplementowaniem w kodzie Stron Internetowych BMS udostępnionego
              przez Google kodu śledzącego (tzw. tracking code). Kod ten bazuje
              na plikach cookies, ale może wykorzystywać inne technologie
              śledzące.
            </p>
            <p>
              Autorzy tych narzędzi gromadzić mogą na swoich serwerach dane
              pozyskiwane z umieszczania na swoich urządzeniach plików cookies i
              korzystać z tych informacji w celu tworzenia raportów i
              świadczenia innych usług związanych z ruchem i korzystaniem z
              Internetu.
            </p>
            <p>
              Firmy będące autorami tych narzędzi mogą również przekazywać te
              informacje osobom trzecim, jeżeli będą zobowiązane to uczynić na
              podstawie przepisów prawa lub w przypadku, gdy osoby te
              przetwarzają takie informacje w imieniu danej firmy.
            </p>
            <p>
              Dane te stanowią jedynie materiał do analizy statystycznej, co
              oznacza, że dane z cookies nie są w żaden sposób łączone z innymi
              danymi, które nam podajesz.
            </p>
            <p>
              Używane przez nas narzędzia Google Analytics i podobne, nie
              stanowią przetwarzania danych.
            </p>
            <li className="fs-6 fw-bold">
              Google AdWords i inne narzędzia Google – reklama zgodna z Twoimi
              preferencjami.
            </li>
            <p className="mt-3">
              Google AdWords i inne narzędzia Google wykorzystują technologię
              cookies, czyli plików tekstowych umieszczanych na Twoim
              urządzeniu, w celu zapamiętania, jakie strony internetowe były
              odwiedzane z Twojego urządzenia.
            </p>
            <p>
              Google gromadzi na swoich serwerach dane pozyskane z umieszczania
              na urządzeniach plików cookies i korzysta z tych informacji, w
              celu wyświetlania reklam na innych odwiedzanych przez Ciebie
              stronach.{" "}
            </p>
            <p>
              Jako reklamodawcy korzystający z usług Google nie mamy dostępu do
              danych z ciasteczek zapisanych na Twoim urządzeniu, ale możemy
              zlecać reklamy do wybranych przez nas odbiorców, np. do wszystkich
              osób, które odwiedziły Strony Internetowe BMS w ciągu ostatnich 5
              dni.
            </p>
            <p>
              Firma Google może również przekazywać informacje osobom trzecim,
              jeżeli będzie zobowiązana to uczynić na podstawie przepisów prawa
              lub w przypadku, gdy osoby te przetwarzają takie informacje w
              imieniu Google.
            </p>
            <p>
              Dane te stanowią dla nas jedynie materiał statystyczny, a reklamy
              zlecamy jedynie na podstawie zbiorczych kryteriów (do określonych
              grup docelowych, a nie do konkretnego użytkownika), co oznacza, że
              dane z cookies nie są w żaden sposób łączone z danymi, które nam
              podajesz.
            </p>
            <p>
              Używane przez nas narzędzia Google AdWords i podobne nie stanowią
              przetwarzania Twoich danych osobowych.
            </p>
            <li className="fs-6 fw-bold">
              Facebook, filmy z serwisu YouTube, firmy Trek oraz funkcje
              społecznościowe.
            </li>
            <p className="mt-3">
              Na Stronach Internetowych BMS może być stosowany tzw. „piksel
              konwersji Facebook” serwisu społecznościowego Facebook w celu
              zarządzania reklamami Facebook i Instagram oraz w celu prowadzenia
              działań remarketingowych.
            </p>
            <p>
              Piksel Facebook śledzi odwiedziny i zachowanie użytkowników
              poruszających siępo Stronach Internetowych BMS. Dzięki temu możemy
              zlecać na portalu Facebook reklamy do tych osób, które były na
              Stronach Internetowych BMS (remarketing) lub na poszczególnych
              podstronach witryn.
            </p>
            <p>
              Następnie otrzymujemy od firmy Facebook jedynie dane statystyczne
              dotyczące skuteczności reklam bez odniesienia do konkretnych osób.
              W ten sposób możemy sprawdzać skuteczność reklam w serwisie
              Facebook i Instagram w celach statystycznych i związanych z
              badaniem rynku.
            </p>
            <p>
              Na Stronach Internetowych BMS możesz obejrzeć filmy z serwisu
              YouTube oraz firmy Trek. Kiedy wyświetlasz taki film, godzisz się
              na wykorzystywanie plików cookies firmy Google LLC dotyczących
              usług YouTube lub plików firmy Trek Bicycle Corporation.
            </p>
            <p>
              Zapewniamy możliwość korzystania z funkcji społecznościowych
              takich, jak udostępnianie treści w serwisach społecznościowych
              oraz subskrybcja profilu społecznościowego.
            </p>
            <p>
              Korzystanie z tych funkcji wiąże się z wykorzystywaniem plików
              cookies administratorów serwisów społecznościowych takich, jak
              Facebook oraz YouTube.
            </p>
            <li className="fs-6 fw-bold">Pozostałe narzędzia - logi serwera.</li>
            <p className="mt-3">
              Korzystanie ze Stron Internetowych BMS wiąże się z przesłaniem
              zapytań do serwera, na którym przechowywana jest strona. Każde
              zapytanie skierowane do serwera zapisywane jest w logach serwera.
            </p>
            <p>
              Logi obejmują m.in. Twój adres IP, datę i czas serwera, informacje
              o przeglądarce internetowej i systemie operacyjnym z jakiego
              korzystasz. Logi zapisywane i przechowywane są na serwerze.
            </p>
            <p>
              Dane zapisane w logach serwera nie są kojarzone z konkretnymi
              osobami korzystającymi z serwisu i nie są wykorzystywane przez nas
              w celu Twojej identyfikacji.
            </p>
            <p>
              Logi serwera stanowią wyłącznie materiał pomocniczy służący do
              administrowania Stronami Internetowymi BMS , a ich zawartość nie
              jest ujawniana nikomu poza osobami upoważnionymi do
              administrowania serwerem.
            </p>
          </ol>
          <h2 className="fs-5 my-3">
            VIII. Jakie są Twoje prawa związane z ochroną danych osobowych?
          </h2>
          <ol>
            <li>
              W każdej chwili masz prawo dostępu do swoich danych, ich
              sprostowania, usunięcia (bycia zapomnianym) lub zmniejszenia
              zakresu, w którym przetwarzamy Twoje dane. Możesz też wnieść
              sprzeciw, wobec przetwarzania Twoich danych na cele marketingowe.
            </li>
            <li>
              W każdej chwili masz prawo do cofnięcia zgody na przetwarzanie
              danych osobowych w określonym celu, jeżeli uprzednio wyraziłeś
              taką zgodę.
            </li>
            <li>
              Możesz w każdej chwili odwołać swoją zgodę na otrzymywanie
              informacji handlowych drogą e-mail i/lub zgodę na marketingowy
              kontakt telefoniczny. Możesz odwołać zgodę poprzez wiadomość
              mailową na adres{" "}
              <a href="mailto:bok@bmsbike.com">bok@bmsbike.com</a>,{" "}
              <a href="mailto:bok@bmsrent.pl">bok@bmsrent.pl</a>. W stopce
              każdej wiadomości, jaka wysyłamy w naszym Newsletterze, również
              znajduje się kontakt, informacja o możliwości dezaktywacji lub
              link dezaktywujący, za pomocą którego możesz wypisać się z listy
              mailingowej.
            </li>
            <li>
              W każdej chwili możesz żądać przeniesienia swoich danych do innego
              administratora danych.
            </li>
            <li>
              Masz prawo wniesienia skargi do organu nadzorczego: Prezes Urzędu
              Ochrony Danych osobowych, w związku ze sposobem przetwarzania
              Twoich danych osobowych
            </li>
            <li>
              Podanie nam Twoich danych jest zazwyczaj dobrowolne. Podanie
              danych do umów jest konieczne w celu ich realizacji. Podanie
              adresu e-mail jest konieczne, jeśli chcesz otrzymywać Newsletter.
            </li>
          </ol>
          <h2 className="fs-5 my-3">IX. Jak zabezpieczamy Twoje dane?</h2>
          <ol>
            <li>
              Twoje dane są przez nas zabezpieczane, poprzez stosowanie środków
              organizacyjnych i technicznych, koniecznych i odpowiednich dla
              zapobiegnięcia ryzyka naruszenia Twoich praw lub wolności.
            </li>
            <li>
              W szczególności zabezpieczamy Twoje dane osobowe przed
              udostępnianiem ich osobom nieupoważnionym a także przed
              uszkodzeniem lub utratą.
            </li>
            <li>
              Bezpieczeństwo przesyłu danych zapewnia stosowanie przez nas
              protokółu transmisji SSL. Polega on na kodowaniu danych przed ich
              wysłaniem z Twojej przeglądarki i dekodowaniu po bezpiecznym
              dotarciu na serwer obsługujący Strony Internetowe BMS.
            </li>
          </ol>
          <h2 className="fs-5 my-3">
            X. Komu powierzamy obsługę płatności elektronicznych?
          </h2>
          <ol>
            <li>
              W celu realizacji płatności elektronicznych korzystamy też z usług
              innych podmiotów w celu umożliwienia użytkownikom dokonywania
              płatności internetowych. Są to podmioty obsługujące systemy
              bankowości elektronicznej:
              <ol type="a">
                <li>PayPal,</li>
                <li>PayByNet,</li>
                <li>Przelewy 24,</li>
                <li>IAI Pay.</li>
              </ol>
            </li>
          </ol>
          <h2 className="fs-5 my-3">XI. Postanowienia końcowe:</h2>
          <ol>
            <li>
              Polityka Prywatności dostosowana jest do wymogów Rozporządzenia
              Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
              2016 r. w sprawie ochrony osób fizycznych w związku z
              przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
              takich danych oraz uchylenia Dyrektywy 95/46/WE- znanego również
              pod skróconą nazwą RODO.
            </li>
            <li>
              Zasady określone w Polityce Prywatności podlegają państwu
              Polskiemu.
            </li>
          </ol>
          <p><b>Właśnie zapoznałeś się/ zapoznałaś się z zasadami ochrony danych osobowych w naszej firmie. Dziękujemy i gratulujemy wytrwałości.</b></p>
          <p><b>Do zobaczenia na rowerowym szlaku!</b></p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
