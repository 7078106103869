import { useMemo, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useLoader } from "../components/app/loader/loader-context";
import { useToast } from "../components/app/toast/toast-context";

// Use environment variables for sensitive data
const rootUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const getSessionId = () => {
    let identifier = localStorage.getItem("identifier");

    if (!identifier) {
        identifier = uuidv4();
        localStorage.setItem("identifier", identifier);
    }

    return identifier;
};

const useCaldisApi = () => {
    const { showToast } = useToast();
    const { showLoader, hideLoader } = useLoader();

    if (!apiKey) {
        console.warn("caldis-api-key is missing");
    }

    const commonOptions = {
        method: "POST",
        headers: {
            ApiKey: apiKey,
            ApiSessionKey: getSessionId(),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    };

    const fetchInProgress = useRef({});
    const fetchWithCommonOptions = async (url, data, callback) => {
        //Prevent duplicating fetch
        const requestId = `${url}:${JSON.stringify(data)}`;
        if (fetchInProgress.current[requestId]) {
            return null;
        }
        fetchInProgress.current[requestId] = true;

        try {
            showLoader();
            const response = await fetch(url, {
                ...commonOptions,
                body: JSON.stringify(data),
            });

            const responseJson = await response.json();

            if (response.ok) {
                callback(responseJson);
                return;
            }

            if (responseJson?.ErrorType === "Exception_Alert") {
                showToast(responseJson.Message, responseJson.AlertType);
                return;
            }
            
            if (responseJson?.ErrorType) {
                throw responseJson;
            }

            showToast("Nieoczekiwany błąd systemu", "danger");
            console.error(`ERROR RESPONSE:`);
            console.error(responseJson);
            throw new Error(`Failed to fetch data from ${url}.`);
        } finally {
            hideLoader();
            fetchInProgress.current[requestId] = false;
        }
    };

    const caldisApi = useMemo(
        () => ({
            getCalendars: async ({ data, callback }) => {
                const url = `${rootUrl}/FlexReservation/GetAll`;
                await fetchWithCommonOptions(url, data, callback);
            },
            getCalendar: async ({ data, callback }) => {
                const url = `${rootUrl}/FlexReservation/Get`;
                await fetchWithCommonOptions(url, data, callback);
            },
            getCalendarsFiltered: async ({ data, callback }) => {
                const url = `${rootUrl}/FlexReservation/GetFiltered`;
                await fetchWithCommonOptions(url, data, callback);
            },
            isCalendarAvailable: async ({ data, callback }) => {
                const url = `${rootUrl}/FlexReservation/IsCalendarAvailable`;
                await fetchWithCommonOptions(url, data, callback);
            },
            getAvailability: async ({ data, callback }) => {
                const url = `${rootUrl}/FlexReservation/GetAvailability`;
                await fetchWithCommonOptions(url, data, callback);
            },
            addReservation: async ({ data, callback }) => {
                const url = `${rootUrl}/FlexReservation/Add`;
                await fetchWithCommonOptions(url, data, callback);
            },
            categories: {
                getAll: async ({ callback }) => {
                    const url = `${rootUrl}/Categories/GetAll`;
                    await fetchWithCommonOptions(url, {}, callback);
                },
            },
            calendars: {
                get: async ({ data, callback }) => {
                    const url = `${rootUrl}/Calendars/Get`;
                    await fetchWithCommonOptions(url, data, callback);
                },
            },
            filters: {
                getAll: async ({ callback }) => {
                    const url = `${rootUrl}/Filters/GetAll`;
                    await fetchWithCommonOptions(url, {}, callback);
                },
            },
            basket: {
                getAll: async ({ callback }) => {
                    const url = `${rootUrl}/Basket/GetAll`;
                    await fetchWithCommonOptions(url, {}, callback);
                },
                count: async ({ callback }) => {
                    const url = `${rootUrl}/Basket/Count`;
                    await fetchWithCommonOptions(url, {}, callback);
                },
                addReservation: async ({ data, callback }) => {
                    const url = `${rootUrl}/Basket/AddReservation`;
                    await fetchWithCommonOptions(url, data, callback);
                },
                deleteReservation: async ({ data, callback }) => {
                    const url = `${rootUrl}/Basket/DeleteReservation`;
                    await fetchWithCommonOptions(url, data, callback);
                },
                orderConfirm: async ({ data, callback }) => {
                    const url = `${rootUrl}/Basket/OrderConfirm`;
                    await fetchWithCommonOptions(url, data, callback);
                },
                getPayment: async ({ data, callback }) => {
                    const url = `${rootUrl}/Basket/GetPayment`;
                    await fetchWithCommonOptions(url, data, callback);
                },
            },
            organization: {
                getPaymentMethods: async ({ callback }) => {
                    const url = `${rootUrl}/Organization/GetPaymentMethods`;
                    await fetchWithCommonOptions(url, {}, callback);
                },
                getBookingAdditionalFields: async ({ callback }) => {
                    const url = `${rootUrl}/Organization/GetBookingAdditionalFields`;
                    await fetchWithCommonOptions(url, {}, callback);
                },
            },
            order: {
                get: async ({ data, callback }) => {
                    const url = `${rootUrl}/Order/Get`;
                    await fetchWithCommonOptions(url, data, callback);
                },
            }
        }),
        []
    );

    return caldisApi;
};

export default useCaldisApi;
