import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useCaldisApi from "../../hooks/useCalidsApi";
import { formatDate, parseDate } from "./../../utils/dateUtils";
import { Container } from "react-bootstrap";

const OrderPage = () => {
    const { orderId } = useParams();
    const caldisApi = useCaldisApi();
    const [order, setOrder] = useState(null);

    console.log(order);
    const fetchOrder = async (filters) => {
        await caldisApi.order.get({
            data: filters,
            callback: (response) => {
                setOrder(response);
            },
        });
    };

    useEffect(() => {
        const filters = {
            id: orderId,
        };

        fetchOrder(filters);
    }, []);

    if (order == null) {
        return null;
    }

    var firstBooking = order.Bookings[0];
    const successfulStatuses = ["Paid", "PartiallyPaid"];
    const statusCss = successfulStatuses.includes(order.PaymentStatusText)
        ? "bg-success"
        : "bg-danger";

    const renderPaymentAmount = () => {
        if (
            order.PaymentStatusText == "PartiallyPaid" &&
            order.OrderPayment?.TotalPriceGross
        ) {
            return (
                <div className="name">
                    {order.OrderPayment.TotalPriceGross.toFixed(2)} zł
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <Container className="my-3 flex-grow-1 d-flex flex-column justify-content-center">
                <div className="container container-a4 mb-3">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-10">
                            <h2 className="h-fancy position-relative">
                                Rezerwacja {firstBooking.FullOrderNumber}
                            </h2>

                            <div className="row g-1">
                                <div className="col">
                                    <div className="p-2 fancy-button bg-circles bg-orange-700 text-white text-center h-100">
                                        <div className="name">
                                            {formatDate(
                                                parseDate(firstBooking.DateStart),
                                                "YYYY-MM-DD"
                                            )}
                                        </div>
                                        <div className="description">
                                            godzina&nbsp;
                                            {formatDate(parseDate(firstBooking.DateStart), "HH:mm")}
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="p-2 fancy-button bg-circles bg-info text-white text-center h-100">
                                        <div className="name">{order.SumGross.toFixed(2)} zł</div>
                                        <div className="description">wartość brutto</div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div
                                        className={`fancy-button bg-circles ${statusCss} text-white text-center fw-500 h-100`}
                                    >
                                        {renderPaymentAmount()}
                                        <div className="description text-lowercase">
                                            {order.PaymentStatusDisplayName}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-10">
                            <div className="virtual-table-stylish mt-1">
                                {order.Bookings.map((b) => {
                                    const room = order.Rooms.find((r) => r.Id === b.IdRoom);

                                    if (!room) {
                                        return null; // Handle the case where the room is not found
                                    }

                                    return (
                                        <div key={b.Id} className="row">
                                            <div className="col-12 col-lg">
                                                <div>
                                                    <p className="fw-bold">{room.DisplayName}</p>
                                                </div>
                                            </div>
                                            <div className="col p-1">
                                                <div>
                                                    <p>
                                                        od {formatDate(b.DateStart, "YYYY-MM-DD HH:mm")}
                                                    </p>
                                                    <p>do {formatDate(b.DateEnd, "YYYY-MM-DD HH:mm")}</p>
                                                </div>
                                            </div>
                                            <div className="col p-1 align-items-center">
                                                <p className="fw-bold text-center m-0 fs-4 text-primary">
                                                    {b.SumGross.toFixed(2)} {b.CurrencyDisplayName}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="col-12 col-xl-10">
                            <h2 className="h-fancy">Szczegóły</h2>
                            <div className="virtual-table-stylish">
                                <div className="row">
                                    <div className="col-6">
                                        <div>
                                            <p className="fw-bold">data utworzenia</p>
                                            {formatDate(
                                                parseDate(firstBooking.DateCreate),
                                                "YYYY-MM-DD"
                                            )}{" "}
                                            godzina{" "}
                                            {formatDate(parseDate(firstBooking.DateCreate), "HH:mm")}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <p className="fw-bold">metoda płatności</p>
                                            <div>{order.PaymentTypeDisplayName}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div>
                                            <p className="fw-bold">dane klienta</p>
                                            {firstBooking.ClientInfo}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div>
                                            <p className="fw-bold">miejsce</p>
                                            {order.Organization.Name}
                                            {order.Organization.OrganizationAddress}
                                            {order.Organization.OrganizationZipCode}{" "}
                                            {order.Organization.OrganizationCity}
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div>
                                            <p className="fw-bold">kontakt</p>
                                            {order.Organization.Email}
                                            {order.Organization.Phone}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default OrderPage;
