import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useToast } from "../app/toast/toast-context";
import { useLoader } from "../app/loader/loader-context";
import useCaldisApi from "./../../hooks/useCalidsApi";

const BasketDeleteModal = ({ reservation, buttonClass }) => {
    const [show, setShow] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { showToast } = useToast();
    const { showLoader, hideLoader } = useLoader();
    const caldisApi = useCaldisApi();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const submitRequest = async () => {
        showLoader();
        setIsSubmitting(true);
        try {
            await caldisApi.basket.deleteReservation({
                data: {
                    Id: reservation.Id,
                },
                callback: () => {
                    window.location.reload();
                },
            });

            console.log("Reservation deleted successfully");
        } catch (error) {
            showToast("Nieoczekiwany błąd systemu", "danger");
            console.error("Error submitting order", error);
        } finally {
            hideLoader();
            setIsSubmitting(false);
        }
    };

    if (reservation == null) {
        return "";
    }

    return (
        <>
            <Button
                variant="danger"
                className={buttonClass}
                onClick={handleShow}
            >
                Usuń
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Usuwanie rezerwacji</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Czy na pewno chcesz usunąć rezerwację{" "}
                    <b>{reservation.Calendar.Name}</b>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        Zamknij
                    </Button>
                    <Button
                        variant="danger"
                        onClick={submitRequest}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Usuwam..." : "Usuń"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

BasketDeleteModal.propTypes = {
    reservation: PropTypes.object,
    buttonClass: PropTypes.string,
};

export default BasketDeleteModal;
