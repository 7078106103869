import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "virtual-select-plugin/dist/virtual-select.min.css";
import "virtual-select-plugin/dist/virtual-select.min.js";

const VirtualSelect = (props) => {
    const eleRef = useRef(null);

    useEffect(() => {
        const defaultProps = {
            placeholder: "-Wybierz-",
            searchPlaceholderText: "Wyszukaj...",
            noOptionsText: "Brak pozycji",
            noSearchResultsText: "Brak pozycji",
            selectAllText: "Zaznacz wszystko",
            clearButtonText: "Wyczyść",
            optionHeight: 30,
        };

        var mergedProps = { ...defaultProps, ...props };
        const { onChange, ...virtualSelectProps } = mergedProps;

        // Initialize VirtualSelect
        window.VirtualSelect.init({
            ele: eleRef.current,
            ...virtualSelectProps,
        });

        if (typeof onChange === "function") {
            eleRef.current.addEventListener("change", onChange);
        }
    }, []);

    return <div ref={eleRef} />;
};

VirtualSelect.propTypes = {
    onChange: PropTypes.func,
    // Add other prop types as needed, for example:
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    placeholder: PropTypes.string,
    searchPlaceholderText: PropTypes.string,
    noOptionsText: PropTypes.string,
    noSearchResultsText: PropTypes.string,
    selectAllText: PropTypes.string,
    clearButtonText: PropTypes.string,
    optionHeight: PropTypes.number,
};

export default VirtualSelect;
