import "./calendar-widget.css";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import useCaldisApi from "../../hooks/useCalidsApi";
import { toUTCDate } from "../../utils/dateUtils";
const CalendarWidget = ({ calendarId }) => {
    const caldisApi = useCaldisApi();

    const getStatusForDate = (date, availabilityMap) => {
        const dateString = toUTCDate(date).toISOString().split("T")[0];
        for (const dayStatus of availabilityMap) {
            if (dayStatus.DateText.split("T")[0] === dateString) {
                return dayStatus.StatusText;
            }
        }
        return null; // Or handle default status
    };

    const getColorForStatus = (isPastDate, status) => {
        if (isPastDate) {
            return "disabled";
        } else {
            switch (status) {
                case "Available":
                    return "bg-available";
                case "Unavailable":
                    return "bg-unavailable";
                case "PartiallyAvailable":
                    return "bg-unavailable";
                default:
                    return "";
            }
        }
    };


    const clearWidget = () => {
        const calendarWrapper = document.querySelector(
            "[data-calendar-wrapper]"
        );
        if (!calendarWrapper) return;
        calendarWrapper.innerHTML = "";
    }

    const renderCalendar = (data) => {
        const calendarWrapper = document.querySelector(
            "[data-calendar-wrapper]"
        );
        if (!calendarWrapper) return;

        // // Clear previous calendar
        // while (calendarWrapper.firstChild) {
        //     calendarWrapper.removeChild(calendarWrapper.firstChild);
        // }

        // Create calendar container
        const calendarContainer = document.createElement("div");
        calendarContainer.classList.add(
            "calendar",
            "calendar-primary",
            "calendar-readonly"
        );
        calendarWrapper.appendChild(calendarContainer);

        // Create month name and navigation
        const monthName = new Date(data.Filter.Date).toLocaleDateString(
            "default",
            { month: "long", year: "numeric" }
        );
        const monthNameElement = document.createElement("div");
        monthNameElement.classList.add("month-name");
        monthNameElement.textContent = monthName;
        calendarContainer.appendChild(monthNameElement);

        // Create calendar grid
        const calendarMonth = document.createElement("div");
        calendarMonth.classList.add("calendar-month");
        calendarContainer.appendChild(calendarMonth);

        let currentDate = new Date(data.Filter.DateStart);
        const endDate = new Date(data.Filter.DateEnd);

        while (currentDate <= endDate) {
            
            const calendarRow = document.createElement("div");
            calendarRow.classList.add("calendar-row");

            for (let i = 0; i < 7; i++) {
                const isPastDate = currentDate < new Date();
                const status = getStatusForDate(
                    currentDate,
                    data.AvailabilityMap.CalendarDayStatuses
                );
                const color = getColorForStatus(isPastDate, status);

                const dayElement = document.createElement("span");
                dayElement.classList.add("day");
                if (color) {
                    dayElement.classList.add(color);
                }

                if (
                    new Date(data.Filter.Date).getMonth() !==
                    currentDate.getMonth()
                ) {
                    dayElement.classList.add("invisible");
                }

                dayElement.textContent = currentDate.getDate();

                calendarRow.appendChild(dayElement);

                currentDate.setDate(currentDate.getDate() + 1);
            }

            calendarMonth.appendChild(calendarRow);
        }
    };

    useEffect(() => {
        const loadAvailabilityWidget = async (filter) => {
            await caldisApi.getAvailability({
                data: {
                    Id: calendarId,
                    Date: filter.Date.toUTCString(),
                },
                callback: (response) => {
                    renderCalendar(response);
                },
            });
        };

        const loadMultipleCalendars = async () => {
            for (let i = 0; i < 3; i++) {
                const date = new Date();
                date.setMonth(date.getMonth() + i);
                await loadAvailabilityWidget({ Date: date });
            }
        };

        clearWidget();
        loadMultipleCalendars();
    }, [calendarId]);

    return (
        <div>
            <div data-calendar-wrapper></div>
        </div>
    );
};

CalendarWidget.propTypes = {
    calendarId: PropTypes.string.isRequired,
};

export default CalendarWidget;
