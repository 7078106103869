import React from "react";
import PropTypes from "prop-types";
import { formatDate, parseDate } from "../../utils/dateUtils";
import Photo from "./../common/photo/Photo";
import BasketDeleteModal from "./BasketDeleteModal";

const BasketMobile = ({ basket }) => {
    const reservations = basket.Organizations[0].Reservations;

    return (
        <>
            <div className="d-block d-xl-none">
                <div className="row">
                    {reservations.map((r) => (
                        <div key={r.Id} className="col-md-6">
                            <div className="card-media p-4 mb-3">
                                <div className="row g-2 h-100">
                                    <div className="col-4">
                                        <Photo
                                            src={r.CalendarPhoto?.Url}
                                            alt={
                                                r.CalendarPhoto?.AlternativeText
                                            }
                                        />
                                    </div>
                                    <div className="col-5">
                                        <h3 className="fs-12px text-truncate m-0">
                                            {r.Calendar.ShortName}
                                        </h3>
                                        <p className="m-0 text-xl-center fs-10px">
                                            <i>
                                                od &nbsp;
                                                <b>
                                                    {formatDate(
                                                        parseDate(r.DateFrom),
                                                        "YYYY-MM-DD HH:mm"
                                                    )}
                                                </b>
                                                <br />
                                                do &nbsp;
                                                <b>
                                                    {formatDate(
                                                        parseDate(r.DateTo),
                                                        "YYYY-MM-DD HH:mm"
                                                    )}
                                                </b>
                                            </i>
                                        </p>
                                    </div>
                                    <div className="col-3">
                                        <div className="d-flex flex-column h-100">
                                            {/* <BasketEditModal reservation={r} /> */}
                                            <BasketDeleteModal
                                                reservation={r}
                                                buttonClass="btn-xs"
                                            />
                                            <div className="d-block d-xl-none mt-auto">
                                                <p className="m-0 mb-2 lh-1 text-primary text-end fw-bold fs-12px">
                                                    {r.SumGross} PLN
                                                    <br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

BasketMobile.propTypes = {
    basket: PropTypes.object.isRequired,
};

export default BasketMobile;
