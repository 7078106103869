import React from 'react';
import PropTypes from 'prop-types';
import { Toast } from 'react-bootstrap';

const ToastComponent = ({  message, background, onClose }) => {
    return (
        <Toast show={true} onClose={onClose} delay={10000} autohide className="mb-1"
        bg={background}>
            <Toast.Header>
                <strong className="me-auto">Powiadomienie</strong>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
        </Toast>
    );
};

ToastComponent.propTypes = {
    message: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    onClose: PropTypes.func,
};

export default ToastComponent;