import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import LayoutHeader from "./layout-header/LayoutHeader";
import LayoutFooter from "./layout-footer/LayoutFooter";
import useCaldisApi from "../../hooks/useCalidsApi";

const Layout = () => {
    const caldisApi = useCaldisApi();
    const [basketCount, setBasketCount] = useState(0);

    useEffect(() => {
        const fetchBasketCount = async (filters) => {
            await caldisApi.basket.count({
                data: filters,
                callback: (response) => {
                    setBasketCount(response.Count);
                },
            });
        };
        fetchBasketCount();
    }, []);

    return (
        <>
            <LayoutHeader basketCount={basketCount} />
            <Outlet />
            <LayoutFooter />
        </>
    );
};

export default Layout;
