import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routes } from "./Config";

import Layout from "./components/layout/Layout";
import HomePage from "./pages/home/HomePage";
import BasketPage from "./pages/basket/BasketPage";

import { ToastProvider } from "./components/app/toast/toast-context";
import { LoaderProvider } from "./components/app/loader/loader-context";
import ScrollToTop from "./components/layout/scroll-to-top/ScrollToTop";
import PaymentProcessingPage from "./pages/payment-processing/PaymentProcessingPage";
import OrderPage from "./pages/order/OrderPage";
import TermsOfServicePage from "./pages/terms-of-service/TermsOfServicePage";
import PrivacyPolicyPage from "./pages/privacy-policy/PrivacyPolicyPage";

const App = () => {
  return (
    <>
      <LoaderProvider>
        <ToastProvider>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path={routes.home} element={<Layout />}>
                <Route index element={<HomePage />} />
                <Route path={routes.basket} element={<BasketPage />} />
                <Route
                  path={routes.paymentProcessing}
                  element={<PaymentProcessingPage />}
                />
                <Route path={routes.order} element={<OrderPage />} />
                <Route
                  path={routes.termsOfService}
                  element={<TermsOfServicePage />}
                />
                <Route
                  path={routes.privacyPolicy}
                  element={<PrivacyPolicyPage />}
                />
                {/* <Route path="*" element={<NoPage />} /> */}
              </Route>
            </Routes>
          </BrowserRouter>
        </ToastProvider>
      </LoaderProvider>
    </>
  );
};

export default App;
