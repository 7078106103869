import './CarouselGallery.css'
import React from "react";
import PropTypes from "prop-types";

const CarouselGallery = ({ photos, currentIndex, handlePrev, handleNext, handleClose }) => {
    return (
        <div className="gallery">
            <div className="gallery-backdrop" onClick={handleClose}></div>
            <div className="gallery-inner">
                <div id="carouselCars" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {photos.map((photo, index) => (
                            <div className={`carousel-item ${index === currentIndex ? 'active' : ''}`} key={photo.Id}>
                                <img src={photo.Url} className="d-block w-100" alt={photo.AlternativeText} />
                            </div>
                        ))}
                    </div>
                    <button className="carousel-control-prev" type="button" onClick={handlePrev}>
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" onClick={handleNext}>
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <button className="gallery-close" type="button" onClick={handleClose}>
                    <span>x</span>
                </button>
            </div>
        </div>
    );
};

CarouselGallery.propTypes = {
    photos: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentIndex: PropTypes.number.isRequired,
    handlePrev: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default CarouselGallery;
