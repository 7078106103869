import React from "react";
import { PropTypes } from "prop-types";
import { Modal } from "react-bootstrap";
import {Button} from "react-bootstrap";
import { routes } from "./../../Config";

const BasketAddedModal = ({ show, onHide }) => {
    return (
        <Modal show={show} onHide={() => onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Dodano do koszyka</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Produkt został dodany do koszyka.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={() => onHide(false)}>
                    Rezerwuj kolejne
                </Button>
                <Button
                    variant="primary"
                    onClick={() => (window.location.href = routes.basket)}
                >
                    Idź do koszyka
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

BasketAddedModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
};
export default BasketAddedModal;
