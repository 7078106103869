import './loader.css'
import React, { createContext, useState, useContext, useCallback  } from 'react';
import PropTypes from 'prop-types';
import Loader from './loader';

const LoaderContext = createContext();

export const useLoader = () => {
    return useContext(LoaderContext);
};

export const LoaderProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    const showLoader = useCallback(() => setLoading(true), []);
    const hideLoader = useCallback(() => setLoading(false), []);

    return (
        <LoaderContext.Provider value={{ showLoader, hideLoader }}>
            {children}
            <Loader show={loading} />
        </LoaderContext.Provider>
    );
};

LoaderProvider.propTypes = {
    children: PropTypes.node.isRequired,
};