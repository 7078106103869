import React from "react";
import { PropTypes } from "prop-types";

const OrderPersonalInfo = ({ additionalFields, register, errors }) => {

    const renderInput = (f, index) => {
        const validationRules = f.IsRequired
            ? {
                required: "Pole wymagane",
                validate: value => value !== "" || "Pole wymagane",
            }
            : {};


        if (f.TypeText === "Select") {
            const values = f.Values.split(',').map(item => item.trim());
            return (
                <select
                    className={`form-select ${errors[f.PropertyName] ? "is-invalid" : ""}`}
                    {...register(f.PropertyName, validationRules)}
                >
                    <option value="">Wybierz opcję</option>
                    {values.map((value) => (
                        <option key={value} value={value}>
                            {value}
                        </option>
                    ))}
                </select>
            );
        } else if (f.TypeText === "Checkbox") {
            return (
                <select
                    className={`form-select ${errors[f.PropertyName] ? "is-invalid" : ""}`}
                    {...register(f.PropertyName, validationRules)}
                >
                    <option value="">Wybierz opcję</option>
                    <option value="true">Tak</option>
                    <option value="false">Nie</option>
                </select>
            );
        } else {
            return (
                <input
                    {...register(f.PropertyName, validationRules)}
                    className={`form-control ${errors[f.PropertyName] ? "is-invalid" : ""}`}
                    placeholder={f.Name}
                />
            );
        }
    }

    const renderAdditionalField = (f, index) => {
        const input = renderInput(f, index);
        return (
            <div key={index} className="mb-3">
                <div className="form-floating">
                    {input}
                    <label htmlFor={f.PropertyName}>{f.Name}</label>
                    {errors[f.PropertyName] && (
                        <div className="invalid-feedback">
                            {errors[f.PropertyName].message}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="mb-3">
                <div className="form-floating">
                    <input
                        {...register("Fullname", { required: "Pole wymagane" })}
                        className={`form-control ${errors.Fullname ? "is-invalid" : ""}`}
                        placeholder="Imię i nazwisko"
                    />
                    <label htmlFor="Fullname">Imię i nazwisko</label>
                    {errors.Fullname && (
                        <div className="invalid-feedback">
                            {errors.Fullname.message}
                        </div>
                    )}
                </div>
            </div>

            <div className="mb-3">
                <div className="form-floating">
                    <input
                        {...register("Email", {
                            required: "Pole wymagane",
                            pattern: {
                                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,  // Regular expression for email validation
                                message: "Niepoprawny format adresu e-mail",  // Error message for invalid email format
                            },
                        })}
                        className={`form-control ${errors.Email ? "is-invalid" : ""}`}
                        placeholder="E-mail"
                    />
                    <label htmlFor="Email">E-mail</label>
                    {errors.Email && (
                        <div className="invalid-feedback">
                            {errors.Email.message}
                        </div>
                    )}
                </div>
            </div>

            <div className="mb-3">
                <div className="form-floating">
                    <input
                        {...register("Phone", {
                            required: "Pole wymagane",
                            pattern: {
                                value: /^[\d\s()+-]{9,15}$/,  // Regular expression for phone number validation
                                message: "Niepoprawny format numeru telefonu",  // Error message for invalid phone format
                            },
                        })}
                        className={`form-control ${errors.Phone ? "is-invalid" : ""}`}
                        placeholder="Telefon"
                    />
                    <label htmlFor="Phone">Telefon</label>
                    {errors.Phone && (
                        <div className="invalid-feedback">
                            {errors.Phone.message}
                        </div>
                    )}
                </div>
            </div>

            {additionalFields.map((f, index) => (
                renderAdditionalField(f, index)
            ))}
        </>
    );
}

OrderPersonalInfo.propTypes = {
    additionalFields: PropTypes.array.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

export default OrderPersonalInfo;
