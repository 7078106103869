import React, { useEffect, useState } from "react";
import useCaldisApi from "./../../hooks/useCalidsApi";
import BasketEmpty from "../../components/basket/BasketEmpty";
import BasketDesktop from "../../components/basket/BasketDesktop";
import BasketMobile from "../../components/basket/BasketMobile";
import OrderModal from "../../components/basket/OrderModal";
import { Container } from "react-bootstrap";
const BasketPage = () => {
    useEffect(() => {
        fetchBasket();
    }, []); // Dependencies array ensures useEffect runs when dateFrom or dateTo changes

    const [basket, setBasket] = useState(null);
    const caldisApi = useCaldisApi();
    const fetchBasket = async () => {
        await caldisApi.basket.getAll({
            callback: (response) => {
                setBasket(response);
            },
        });
    };

    if (basket?.Organizations.length == 1) {
        return (
            <>
                <Container className="my-3 flex-grow-1 d-flex flex-column justify-content-center">
                    <h1 className="fs-20px">Twój koszyk</h1>
                    <BasketDesktop basket={basket} />
                    <BasketMobile basket={basket} />

                    <OrderModal basket={basket} />
                </Container>
            </>
        );
    }

    return <BasketEmpty />;
};

export default BasketPage;
