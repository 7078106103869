import React from "react";
import { Col, Row } from "react-bootstrap";
import CalendarItem from "./calendar-item/CalendarItem";
import PropTypes from "prop-types";
import "./CalendarsList.css";

const CalendarsList = ({ calendars, dateFrom, dateTo, timeFrom, timeTo }) => {
  if (!calendars) {
    return (
      <Row className="calendars g-3 mt-3">
        <Col>
        </Col>
      </Row>
    );
  }
  return (
    <>
      <Row className="calendars g-3 mt-3">
        {calendars.map((calendar) => (
          <CalendarItem
            key={calendar.Id}
            calendar={calendar}
            dateFrom={dateFrom}
            dateTo={dateTo}
            timeFrom={timeFrom}
            timeTo={timeTo}
          />
        ))}
      </Row>
    </>
  );
};

CalendarsList.propTypes = {
  calendars: PropTypes.arrayOf(PropTypes.object),
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  timeFrom: PropTypes.string,
  timeTo: PropTypes.string,
};

export default CalendarsList;
