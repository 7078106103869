import React, { useState } from "react";
import PropTypes from "prop-types";
import CalendarPhoto from "./CalendarPhoto";
import CarouselGallery from "./CarouselGallery";

const CalendarPhotoGallery = ({ calendar }) => {
    const [showGallery, setShowGallery] = useState(false);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

    const handlePhotoClick = (index) => {
        setCurrentPhotoIndex(index);
        setShowGallery(true);
    };

    const handleCloseGallery = () => {
        setShowGallery(false);
    };

    const handlePrev = () => {
        setCurrentPhotoIndex((prevIndex) =>
            prevIndex === 0 ? calendar.Photos.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentPhotoIndex((prevIndex) =>
            prevIndex === calendar.Photos.length - 1 ? 0 : prevIndex + 1
        );
    };

    if (calendar == null || calendar.Photos.length == 0) {
        return null;
    }

    const [MainPhoto, ...AdditionalPhotos] = calendar.Photos;

    return (
        <>
            {showGallery && (
                <CarouselGallery
                    photos={calendar.Photos}
                    currentIndex={currentPhotoIndex}
                    handlePrev={handlePrev}
                    handleNext={handleNext}
                    handleClose={handleCloseGallery}
                />
            )}
            <div className="mb-3">
                <div className="row g-1">
                    <div className="col-auto">
                        <CalendarPhoto
                            photo={MainPhoto}
                            index={0}
                            onPhotoClick={handlePhotoClick}
                        />
                    </div>

                    {/* desktop view */}
                    <div className="col-auto d-none d-lg-block">
                        {AdditionalPhotos.map((photo, index) => (
                            <div
                                key={photo.Id}
                                style={{ width: 150 }}
                                className="p-0 mb-1"
                            >
                                <CalendarPhoto
                                    photo={photo}
                                    index={index + 1}
                                    onPhotoClick={handlePhotoClick}
                                />
                            </div>
                        ))}
                    </div>

                    {/* mobile view */}
                    <div className="col-12 d-flex d-lg-none">
                        <div className="row g-1">
                            {AdditionalPhotos.map((photo, index) => (
                                <div className="col-auto" key={photo.Id}>
                                    <div style={{ maxWidth: 100 }} className="p-0">
                                        <CalendarPhoto
                                            photo={photo}
                                            index={index + 1}
                                            onPhotoClick={handlePhotoClick}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

CalendarPhotoGallery.propTypes = {
    calendar: PropTypes.object.isRequired,
};

export default CalendarPhotoGallery;
