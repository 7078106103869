import React from "react";

const TermsOfServicePage = () => {
  return (
    <div className="container  mb-3">
      <div className="row justify-content-center">
        <div className="col-12 col-xl-8">
          <h1 className="mt-5 mb-3 fs-4">
            REGULAMIN WYPOŻYCZALNI ROWERÓW BMS W SZCZECINIE
          </h1>

          <div className="section">
            <h2 className="text-center fs-5">§ 1</h2>
            <p className="text-justify">
              Niniejszy Regulamin określa warunki oraz zasady korzystania z
              rowerów, rowerów elektrycznych, sprzętu i wyposażenia
              turystycznego zwanych w dalszej części: „sprzętem” dla klientów
              indywidualnych i instytucjonalnych korzystających z wypożyczalni
              rowerów Bike Mountain Singletrack (dalej: „BMS” lub
              „Wypożyczający”) w Szczecinie.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 2</h2>
            <h3 className="fs-5 fw-bold">Definicje</h3>
            <p className="text-justify">
              <b>1. Regulamin</b> - niniejszy Regulamin opisujący zasady i
              warunki korzystania, w szczególności warunki, zakres praw i
              obowiązków oraz odpowiedzialność osób korzystających z
              wypożyczania sprzętu w wypożyczalni „BMS”. Akceptacja postanowień
              Regulaminu i spełnienie wszystkich warunków w nim określonych
              stanowi podstawę i warunek zezwolenia na wypożyczenie sprzętu w
              wypożyczalni „BMS”.
            </p>
            <p className="text-justify">
              <b>2. Najemca</b> – pełnoletnia osoba fizyczna lub osoba prawna,
              będąca stroną <i>Umowy najmu</i>.
            </p>
            <p className="text-justify">
              <b>3. Wynajmujący</b> – Marek Gąsior, prowadzący działalność
              gospodarczą pod nazwą Sklep Rowerowy „Bike Multi Sport” Marek
              Gąsior, ul. Rzemieślnicza 6, 72-100 Goleniów, NIP 856-102-57-34
            </p>
            <p className="text-justify">
              <b>4. Rower elektryczny</b> - rower posiadający dodatkowe
              wspomaganie, zasilany silnikiem elektrycznym z akumulatora,
              wynajmowany Najemcy w ramach <i>Umowy najmu</i>.
            </p>
            <p className="text-justify">
              <b>5. Usługa najmu</b> – wynajem sprzętu na warunkach określonych
              w <i>Umowie najmu</i>. <i>Umowa</i> reguluje w szczególności
              liczbę i typ rowerów, czas najmu (datę wynajmu i godzinę zwrotu),
              liczbę dni, miejsce wynajmu i zwrotu, jak również wynajem
              dodatkowych elementów sprzętu i wyposażenia oraz ewentualne
              świadczenia dodatkowe.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 3</h2>
            <p className="text-justify">
              Wypożyczenie sprzętu i złożenie podpisu na <i>Umowie najmu</i>{" "}
              potwierdza, że wypożyczający zapoznał się z treścią niniejszego
              Regulaminu i go akceptuje.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 4</h2>
            <p className="text-justify">
              Wypożyczany sprzęt jest własnością Marka Gąsiora, prowadzącego
              działalność gospodarczą pod nazwą Sklep Rowerowy „Bike Multi
              Sport” Marek Gąsior, ul. Rzemieślnicza 6, 72-100 Goleniów, NIP
              856-102-57-34
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 5</h2>
            <p className="text-justify">
              Wypożyczany sprzęt jest sprawny technicznie, ale może nosić ślady
              wcześniejszego użytkowania.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 6</h2>
            <p className="text-justify">
              Wypożyczenie sprzętu następuje po przedstawieniu przez Najemcę
              dwóch dokumentów potwierdzających tożsamość, wniesieniu opłaty za
              najem sprzętu zgodnie z cennikiem wypożyczalni oraz kaucji.
              Szczegółowe warunki najmu określa <i>Umowa najmu</i>.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 7</h2>
            <p className="text-justify">
              1. Przekazanie i odbiór sprzętu może nastąpić w następujący
              sposób:
            </p>
            <p>
              - z dostawą po stronie Wynajmującego – w miejscu i czasie
              ustalonym między stronami Umowy Najmu osobiście, mailowo lub
              telefonicznie:
              <br />
              <a href="mailto:szczecin@bmsrent.pl">szczecin@bmsrent.pl</a>, tel.
              <a href="tel:789808173"> 789 808 173</a>,
            </p>
            <p className="text-justify">
              - z odbiorem własny przez Najemcę - w punkcie BMS (SKK) przy Al.
              Wojska Polskiego 246 w Szczecinie w godzinach ustalonych między
              stronami Umowy Najmu mailowo lub telefonicznie:
              <br />
              <a href="mailto:szczecin@bmsrent.pl">szczecin@bmsrent.pl</a>, tel.
              <a href="tel:789808173"> 789 808 173</a>.
            </p>
            <p className="text-justify">
              2. Sposób dostawy i zwrotu sprzętu zostanie zawarty w Umowie
              najmu.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 8</h2>
            <p className="text-justify">
              1. Czas wypożyczenia sprzętu liczony jest w dniach najmu.
            </p>
            <p className="text-justify">
              2. Dzień najmu obejmuje okres do 10 godzin od momentu przekazania
              sprzętu.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 9</h2>
            <p className="text-justify">
              1. Najemca wynajmuje sprzęt po dokonaniu rezerwacji za
              pośrednictwem strony internetowej:{" "}
              <a href="mailto:szczecin.bmsrent.pl">szczecin.bmsrent.pl</a>
            </p>
            <p className="text-justify">
              2. Na poczet rezerwacji sprzętu Wynajmujący pobiera bezzwrotną
              przedpłatę w wysokości 50% całkowitego kosztu wypożyczenia.
            </p>
            <p className="text-justify">
              3. Bezzwrotna przedpłata stanowi część zapłaty Najemcy dla
              Wynajmującego za wypożyczony sprzęt.
            </p>
            <p className="text-justify">
              4. W przypadku nieodebrania sprzętu w pierwszym dniu rezerwacji
              wpłacona bezzwrotna przedpłata przepada na rzecz Wynajmującego
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 10</h2>
            <p className="text-justify">
              Wynajmujący może odmówić wypożyczenia sprzętu w szczególności,
              gdy:
            </p>
            <p className="text-justify">
              1. Osoba chcąca wynająć sprzęt znajduje się w stanie
              nietrzeźwości, w stanie wskazującym na spożycie alkoholu, w stanie
              wskazującym na spożycie środków odurzających, substancji
              psychotropowych lub innych tego typu substancji oddziałujących na
              centralny układ nerwowy.
            </p>
            <p className="text-justify">
              2. Osoba chcąca wynająć sprzęt nie przedstawi dwóch dokumentów
              potwierdzających tożsamość.
            </p>
            <p className="text-justify">
              3. Osoba chcąca wynająć sprzęt nie wpłaci kaucji.
            </p>
            <p className="text-justify">
              4. Osoba chcąca wynająć sprzęt odmówi podpisania{" "}
              <i>Umowy najmu</i>.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 11</h2>
            <p className="text-justify">
              Niniejszy regulamin obowiązuje od 17 marca 2023 r.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfServicePage;
