import React from "react";
import { Container } from "react-bootstrap";

const BasketEmpty = () => {
    return (
        <>
            <Container className="my-3 flex-grow-1 d-flex flex-column justify-content-center">
                <div className="container container-a4 mb-3 h-100">
                    <div className="row justify-content-center h-100">
                        <div className="col-12 col-xl-10 h-100 d-flex align-items-center justify-content-center">
                            <div className="d-flex flex-row align-items-center">
                                <div className="me-3">
                                    <i
                                        className="bi-basket2"
                                        style={{
                                            fontSize: "4rem",
                                            display: "inline-block",
                                            transform: "rotate(145deg)",
                                        }}
                                    ></i>
                                </div>

                                <div>
                                    <h1 className="fs-5">
                                        Twój koszyk jest pusty
                                    </h1>

                                    <p>
                                        Wybierz interesujące Cię produkty, a
                                        następnie dokonaj rezerwacji
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default BasketEmpty;
