import React from 'react';
import PropTypes from 'prop-types';
import './TitleWidget.css'; // Import a CSS file for styling if needed

const TitleWidget = ({ children }) => {
    return (
        <div className="title-widget">
            {children}
        </div>
    );
};

TitleWidget.propTypes = {
    children: PropTypes.node.isRequired,
};

export default TitleWidget;