import React from "react";
import PropTypes from "prop-types";
import { formatDate, parseDate } from "../../utils/dateUtils";
import Photo from "./../common/photo/Photo";
import BasketDeleteModal from "./BasketDeleteModal";

const BasketDesktop = ({ basket }) => {
    const reservations = basket.Organizations[0].Reservations;

    return (
        <>
            <div className="d-none d-xl-block">
                {reservations.map((r) => (
                    <div key={r.Id} className="card-media p-3 mb-3">
                        <div className="row g-2 justify-content-center">
                            <div className="col-4 col-xl-2">
                                <Photo
                                    src={r.CalendarPhoto?.Url}
                                    alt={r.CalendarPhoto?.AlternativeText}
                                />
                            </div>
                            <div className="col-8 col-xl-10">
                                <div className="row h-100">
                                    <div className="col-xl-3">
                                        <div className="d-flex flex-column justify-content-center h-100">
                                            <h3 className="fs-5 text-xl-center text-truncate m-0">
                                                {r.Calendar.ShortName}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-xl-3">
                                        <div className="d-flex flex-column justify-content-center h-100">
                                            <p className="m-0 text-xl-center">
                                                <i>
                                                    od &nbsp;
                                                    <b>
                                                        {formatDate(
                                                            parseDate(
                                                                r.DateFrom
                                                            ),
                                                            "YYYY-MM-DD HH:mm"
                                                        )}
                                                    </b>
                                                    <br />
                                                    do &nbsp;
                                                    <b>
                                                        {formatDate(
                                                            parseDate(r.DateTo),
                                                            "YYYY-MM-DD HH:mm"
                                                        )}
                                                    </b>
                                                </i>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-xl-2">
                                        <div className="d-flex flex-column justify-content-center h-100">
                                            <p className="fw-bold text-xl-center m-0 mt-1 lh-1">
                                                {r.PriceGross} PLN
                                            </p>
                                            <p className="text-xl-center m-0 lh-1">
                                                cena za dobę
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-xl-2">
                                        <div className="d-flex flex-column justify-content-center h-100">
                                            <div>
                                                <p className="fw-bold text-center m-0 mt-1 lh-1 fs-5 text-primary">
                                                    {r.SumGross} PLN
                                                </p>
                                                <p className="text-center m-0 lh-1">
                                                    łącznie
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-2">
                                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                            {/* <BasketEditModal reservation={r}/> */}
                                            <BasketDeleteModal
                                                reservation={r}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

BasketDesktop.propTypes = {
    basket: PropTypes.object.isRequired,
};

export default BasketDesktop;
