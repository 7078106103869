import React from "react";
import "./ArticleHeader.css";
import { Helmet } from "react-helmet";

/* eslint-disable react/no-unknown-property */
const ArticleHeader = () => {
  return (
    <>
      <Helmet>
        <link rel="preload" href="/img/xxs/slide3.webp" as="image" media="(max-width: 375px)" type="image/webp" />
        <link rel="preload" href="/img/xs/slide3.webp" as="image" media="(max-width: 575px)" type="image/webp" />
        <link rel="preload" href="/img/xl/slide3.webp" as="image" media="(min-width: 576px)" type="image/webp" />
      </Helmet>

      <div className="container-fluid container-xl px-0 px-xl-12px">
        <div className="row">
          <div className="col-12">
            <div className="jumbotron">
              <picture>
                <source
                  srcSet="/img/xxs/slide3.webp"
                  media="(max-width: 375px)"
                  type="image/webp"
                />

                <source
                  srcSet="/img/xs/slide3.webp"
                  media="(max-width: 575px)"
                  type="image/webp"
                />

                <img
                  className="img-fluid w-100"
                  src="/img/xl/slide3.webp"
                  alt="Wypożyczalnia rowerów w Szczecinie - BMS"
                  width="1200"
                  height="400"
                />
              </picture>

              <div className="description">
                <h1 className="display-5 fw-500 mb-3">
                  Wypożyczalnia rowerów BMS - rezerwuj online
                </h1>
                <p className="lead d-none d-sm-block">
                  Lekkie rowery MTB i elektryczne MTB dla amatorów,
                  zaawansowanych bikerów oraz dla rodzin z dziećmi. <br />
                  <span className="fw-bold">NOWOŚĆ: e-gravele</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
/* eslint-enable react/no-unknown-property */
export default ArticleHeader;
