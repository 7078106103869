import ArticleHeader from "../../components/home/ArticleHeader";
import CalendarsList from "../../components/home/CalendarsList";
import React, { useEffect, useState } from "react";

import { CalendarDetailsModalProvider } from "../../components/calendar/calendar-details-modal-context";

import useCaldisApi from "./../../hooks/useCalidsApi";
import DynamicFilters from "../../components/home/dynamic-filters/DynamicFilters";
import { mergeDateTime } from "../../utils/dateUtils";
import { Container } from "react-bootstrap";

const HomePage = () => {
    const [calendars, setCalendars] = useState(null);

    const caldisApi = useCaldisApi();

    // Helper function to format date for input type="datetime-local"
    const formatDateForInput = (date) => {
        const pad = (num) => num.toString().padStart(2, "0");
        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate());
        return `${year}-${month}-${day}`;
    };

    // Load saved dates from localStorage if available
    const loadSavedDates = () => {
        const savedDateFrom = localStorage.getItem('SearchDateFrom');
        const savedDateTo = localStorage.getItem('SearchDateTo');

        const today = new Date();
        const defaultDateFrom = savedDateFrom ? new Date(savedDateFrom) : new Date(today);
        const defaultDateTo = savedDateTo ? new Date(savedDateTo) : new Date(today);


        if (savedDateFrom && savedDateTo) {
            const dateFrom = new Date(savedDateFrom);
            const dateTo = new Date(savedDateTo);

            if (dateFrom >= today && dateTo >= today) {
                return {
                    defaultDateFrom: formatDateForInput(dateFrom),
                    defaultDateTo: formatDateForInput(dateTo)
                };
            } else {
                // Clear outdated dates from localStorage
                localStorage.removeItem('SearchDateFrom');
                localStorage.removeItem('SearchDateTo');
            }
        }

        return {
            defaultDateFrom: formatDateForInput(defaultDateFrom),
            defaultDateTo: formatDateForInput(defaultDateTo)
        };
    };

    // Set default dates
    const { defaultDateFrom, defaultDateTo } = loadSavedDates();

    const [dateFrom, setDateFrom] = useState(defaultDateFrom);
    const [dateTo, setDateTo] = useState(defaultDateTo);
    const [dynamicFilters, setDynamicFilters] = useState([]);

    const [timeFrom, setTimeFrom] = useState("09:00");
    const [timeTo, setTimeTo] = useState("17:00");

    const fetchCalendars = async (filters) => {
        await caldisApi.getCalendars({
            data: filters,
            callback: (response) => {
                setCalendars(response);
            },
        });
    };

    const handleDatePickerClick = (e) => {
        const sourceInput = e.target;
        if (sourceInput) {
            sourceInput.showPicker();
        }
    };

    const handleDateFromChange = (e) => {
        const newDateFrom = e.target.value;
        setDateFrom(newDateFrom);
        localStorage.setItem('SearchDateFrom', newDateFrom);
        if (new Date(newDateFrom) > new Date(dateTo)) {
            const adjustedDate = new Date(new Date(newDateFrom));
            setDateTo(formatDateForInput(adjustedDate));
            localStorage.setItem('SearchDateTo', adjustedDate);
        }
    };

    const handleDateToChange = (e) => {
        const newDateTo = e.target.value;
        setDateTo(newDateTo);
        localStorage.setItem('SearchDateTo', newDateTo); // Save to localStorage
        if (new Date(newDateTo) < new Date(dateFrom)) {
            const adjustedDate = new Date(new Date(newDateTo));
            setDateFrom(formatDateForInput(adjustedDate));
            localStorage.setItem('SearchDateFrom', adjustedDate);
        }
    };

    const handleFilterChange = (filters) => {
        setDynamicFilters(filters);
    };

    useEffect(() => {
        const filters = {
            DateFrom: mergeDateTime(dateFrom, timeFrom),
            DateTo: mergeDateTime(dateTo, timeTo),
            Filters: dynamicFilters,
        };

        fetchCalendars(filters);
    }, [dateFrom, dateTo, timeFrom, timeTo, dynamicFilters]); // Dependencies array ensures useEffect runs when dateFrom or dateTo changes

    return (
        <>
            <ArticleHeader />

            <Container className="my-3 flex-grow-1 d-flex flex-column justify-content-center">
                <form id="searchForm">
                    <div className="row g-1">
                        <div className="col-md-3">
                            <div className="mb-1 form-group form-group-filter">
                                <label htmlFor="DateFrom">
                                    Data od
                                </label>
                                <input
                                    className="form-control form-control-colored"
                                    type="date"
                                    name="DateFrom"
                                    value={dateFrom}
                                    onClick={handleDatePickerClick}
                                    onChange={handleDateFromChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-1 form-group form-group-filter">
                                <label htmlFor="TimeFrom">
                                    Godzina od
                                </label>
                                <input
                                    className="form-control"
                                    type="time"
                                    name="TimeFrom"
                                    value={timeFrom}
                                    onChange={handleDateFromChange}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-1 form-group form-group-filter">
                                <label htmlFor="DateTo">
                                    Data do
                                </label>
                                <input
                                    className="form-control form-control-colored"
                                    type="date"
                                    name="DateTo"
                                    value={dateTo}
                                    onClick={handleDatePickerClick}
                                    onChange={handleDateToChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-1 form-group form-group-filter">
                                <label htmlFor="TimeTo">
                                    Godzina do
                                </label>
                                <input
                                    className="form-control"
                                    type="time"
                                    name="TimeTo"
                                    value={timeTo}
                                    onChange={handleDateToChange}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <DynamicFilters onChange={handleFilterChange} />
                </form>

                <CalendarDetailsModalProvider>
                    <CalendarsList
                        calendars={calendars}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        timeFrom={timeFrom}
                        timeTo={timeTo}
                    />
                </CalendarDetailsModalProvider>
            </Container>
        </>
    );
};

export default HomePage;
