import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

const Loader = ({ show }) => {
    if (!show) return null;

    return (
        <>
            <div className="loader loader-fixed">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
            <div className="loader-backdrop"></div>
        </>
    );
};

Loader.propTypes = {
    show: PropTypes.bool.isRequired,
};

export default Loader;