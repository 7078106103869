import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import CalendarDetailsnModal from './calendar-details';

const CalendarDetailsnModalContext = createContext();

export const useCalendarDetailsModal = () => {
  return useContext(CalendarDetailsnModalContext);
};

export const CalendarDetailsModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    show: false,
    calendarId: null,
  });

  const showModal = (calendarId, dateFrom, dateTo) => {
    setModalState({ show: true, calendarId, dateFrom, dateTo });
  };

  const hideModal = () => {
    setModalState({ show: false, calendarId: null });
  };

  return (
    <CalendarDetailsnModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      <CalendarDetailsnModal
        show={modalState.show}
        calendarId={modalState.calendarId}
        dateFrom={modalState.dateFrom}
        dateTo={modalState.dateTo}
        handleClose={hideModal}
      />
    </CalendarDetailsnModalContext.Provider>
  );
};

CalendarDetailsModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
