import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useCaldisApi from "../../hooks/useCalidsApi";
import { routes } from "../../Config";
import { Container } from "react-bootstrap";

const PaymentProcessingPage = () => {
    const { paymentId } = useParams();
    const [loading, setLoading] = useState(true);
    const [orderId, setOrderId] = useState(null);
    const caldisApi = useCaldisApi();
    useEffect(() => {
        const checkPaymentStatus = async () => {
            await caldisApi.basket.getPayment({
                data: {
                    id: paymentId,
                },
                callback: (response) => {
                    if (response.Payment.StatusText == "Paid") {
                        setLoading(false);
                        setOrderId(response.Payment.IdOrder);
                        clearInterval(intervalId);
                    }
                },
            });
        };

        // Check payment status immediately on component mount
        checkPaymentStatus();

        // Set up an interval to check the payment status every 10 seconds
        const intervalId = setInterval(checkPaymentStatus, 10000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [paymentId]);

    useEffect(() => {
        if (orderId) {
            // Redirect after 5 seconds
            const timerId = setTimeout(() => {
                location.href = routes.order.replace(":orderId", orderId);
            }, 5000);

            // Clear timeout if the component unmounts or loading changes back to true
            return () => clearTimeout(timerId);
        }
    }, [orderId]);

    if (loading) {
        return (
            <>
                <Container className="my-3 flex-grow-1 d-flex flex-column justify-content-center">
                    <div className="container container-a4 mb-3 h-100">
                        <div className="row justify-content-center h-100">
                            <div className="col-12 col-xl-10 h-100 d-flex align-items-center justify-content-center">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="me-3">
                                        <div
                                            className="spinner-border"
                                            role="status"
                                        ></div>
                                    </div>

                                    <div>
                                        <h1 className="fs-5">
                                            Przetwarzanie płatności
                                        </h1>

                                        <p className="mb-0">
                                            Twoja płatność jest przetwarzana.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </>
        );
    }

    return (
        <>
            <div className="container container-a4 mb-3 h-100">
                <div className="row justify-content-center h-100">
                    <div className="col-12 col-xl-10 h-100 d-flex align-items-center justify-content-center">
                        <div className="d-flex flex-row align-items-center">
                            <div className="me-3">
                                <i
                                    className="bi-currency-dollar"
                                    style={{
                                        fontSize: "4rem",
                                        display: "inline-block",
                                    }}
                                ></i>
                            </div>

                            <div>
                                <h1 className="fs-5">Opłacono</h1>

                                <p className="mb-0">
                                    Twoja rezerwacja została opłacona.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentProcessingPage;
