import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import useCaldisApi from "../../hooks/useCalidsApi";

const OrderPaymentInfo = ({ register, errors }) => {
    const caldisApi = useCaldisApi();
    const [paymentMethods, setPaymentMethods] = useState([]);

    const fetchPaymentMethods = async () => {
        await caldisApi.organization.getPaymentMethods({
            callback: (response) => {
                setPaymentMethods(response);
            },
        });
    };

    useEffect(() => {
        fetchPaymentMethods();
    }, []);

    return (
        <>
            <div className="mb-3">
                <div className="form-floating">
                    <input
                        {...register("TotalPriceGross", {
                            required: "Pole wymagane",
                        })}
                        className="form-control"
                        readOnly
                    />
                    <label htmlFor="TotalPriceGross">Suma brutto</label>
                    {errors.TotalPriceGross && (
                        <div className="invalid-feedback">
                            {errors.TotalPriceGross.message}
                        </div>
                    )}
                </div>
            </div>

            <div className="mb-3">
                <div className="form-floating">
                    <input
                        {...register("TotalDeposit", {
                            required: "Pole wymagane",
                        })}
                        className="form-control"
                        readOnly
                    />
                    <label htmlFor="TotalDeposit">Depozyt (płatny na miejscu)</label>
                    {errors.TotalDeposit && (
                        <div className="invalid-feedback">
                            {errors.TotalDeposit.message}
                        </div>
                    )}
                </div>
            </div>

            <div className="mb-3">
                <div className="form-floating">
                    <select
                        {...register("PaymentMethod", {
                            required: "Pole wymagane",
                        })}
                        className={`form-select ${errors.PaymentMethod ? "is-invalid" : ""
                            }`}
                    >
                        <option value="">Wybierz metodę płatności</option>
                        {paymentMethods.map((method, idx) => (
                            <option key={idx} value={method.Value}>
                                {method.Text}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="PaymentMethod">Metoda płatności</label>
                    {errors.PaymentMethod && (
                        <div className="invalid-feedback">
                            {errors.PaymentMethod.message}
                        </div>
                    )}
                </div>
            </div>

            {/* <div id="P24Image" className="mb-3 hidden">
      <img className="img-fluid" src="/Content/images/Przelewy24_logo.webp" alt="Przelewy24 logo" />
    </div> */}

            <div className="form-check">
                <input
                    {...register("AcceptTermsOfService", {
                        required: "Zgoda wymagana",
                    })}
                    className={`form-check-input ${errors.AcceptTermsOfService ? "is-invalid" : ""
                        }`}
                    type="checkbox"
                />
                <label className="form-check-label required">
                    Zapoznałem/łam się z {" "}
                    <a href="https://caldis.pl/regulamin" target="_blank" rel="noreferrer">
                        pouczeniem o prawie odstąpienia od umowy świadczenia
                        usług drogą elektroniczną
                    </a>
                    , {" "}
                    <a href="https://caldis.pl/portal-klienta/regulamin-rezerwacji-dla-klientow" target="_blank" rel="noreferrer">
                        regulaminem rezerwacji
                    </a>
                    , {" "}
                    <a href="https://caldis.pl/regulamin" target="_blank" rel="noreferrer">
                        regulaminem serwisu
                    </a> {" "}
                    oraz

                    {" "}
                    <a href="https://caldis.pl/portal-klienta/regulamin-aplikacji-dla-klientow" target="_blank" rel="noreferrer">
                        regulaminem aplikacji mobilnej
                    </a>
                    .
                </label>
                {errors.AcceptTermsOfService && (
                    <div className="invalid-feedback">
                        {errors.AcceptTermsOfService.message}
                    </div>
                )}
            </div>

            <div className="form-check">
                <input
                    {...register("Agreement1", { required: "Zgoda wymagana" })}
                    className={`form-check-input ${errors.Agreement1 ? "is-invalid" : ""
                        }`}
                    type="checkbox"
                />
                <label className="form-check-label required">
                    Zapoznałem się z zasadami ochrony moich danych osobowych
                    zamieszczonymi w {" "}
                    <a href="https://caldis.pl/polityka-prywatnosci" target="_blank" rel="noreferrer">
                        Polityce prywatności
                    </a>
                    .
                </label>
                {errors.Agreement1 && (
                    <div className="invalid-feedback">
                        {errors.Agreement1.message}
                    </div>
                )}
            </div>

            <div className="form-check">
                <input
                    {...register("Agreement3", { required: "Zgoda wymagana" })}
                    className={`form-check-input ${errors.Agreement3 ? "is-invalid" : ""
                        }`}
                    type="checkbox"
                />
                <label className="form-check-label required">
                    Zapoznałem się z <a href="/regulamin">Regulaminem strony www</a> oraz zasadami ochrony moich danych osobowych
                    zamieszczonymi w {" "}
                    <a href="/polityka-prywatnosci" target="_blank">
                        Polityce prywatności strony www
                    </a>.
                </label>
                {errors.Agreement3 && (
                    <div className="invalid-feedback">
                        {errors.Agreement3.message}
                    </div>
                )}
            </div>

            <div className="form-check">
                <input
                    {...register("Agreement2", { required: "Zgoda wymagana" })}
                    className={`form-check-input ${errors.Agreement2 ? "is-invalid" : ""
                        }`}
                    type="checkbox"
                />
                <label className="form-check-label required">
                    Wyrażam zgodę na wykonanie usługi w pełni. Jestem świadomy,
                    że z chwilą całkowitego spełnienia świadczenia przez
                    usługodawcę stracę prawo do odstąpienia od umowy.
                </label>
                {errors.Agreement2 && (
                    <div className="invalid-feedback">
                        {errors.Agreement2.message}
                    </div>
                )}
            </div>
        </>
    );
};

OrderPaymentInfo.propTypes = {
    register: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

export default OrderPaymentInfo;
