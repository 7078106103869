import "./LayoutHeader.css";
import React, {useState} from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import { routes } from "../../../Config";
import Badge from "react-bootstrap/Badge";

const LayoutHeader = ({ basketCount }) => {
    
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(expanded ? false : "expanded");
    };
    const handleNavClick = () => {
        setExpanded(false);
    };
    return (
        <Navbar expanded={expanded} expand="lg">
            <Container>
                <Navbar.Brand as={Link} to={routes.home}>
                    <img
                        src="/img/logo.webp"
                        className="img-fluid"
                        alt="Logo firmy"
                        width="225"
                        height="75"
                    />
                </Navbar.Brand>
                <Navbar.Toggle  onClick={handleToggle} />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="ms-lg-auto">
                        <Nav.Link as={Link} to={routes.home} onClick={handleNavClick}>
                            Strona główna
                        </Nav.Link>
                        <Nav.Link as={Link} to={routes.basket} onClick={handleNavClick}>
                            Koszyk
                            <Badge
                                bg="primary"
                                className="mx-1"
                                style={{
                                    display:
                                        basketCount > 0 ? "inline" : "none",
                                }}
                            >
                                {basketCount}
                            </Badge>
                        </Nav.Link>
                        <Nav.Link as={Link} to={routes.termsOfService} onClick={handleNavClick}>
                            Regulamin
                        </Nav.Link>
                        <Nav.Link as={Link} to={routes.privacyPolicy} onClick={handleNavClick}>
                            Polityka prywatności
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

LayoutHeader.propTypes = {
    basketCount: PropTypes.number.isRequired,
};

export default LayoutHeader;
