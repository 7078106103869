import "./CalendarItem.css";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col } from "react-bootstrap";
import { useCalendarDetailsModal } from "../../calendar/calendar-details-modal-context";
import useCaldisApi from "./../../../hooks/useCalidsApi";
import BasketAddedModal from "../..//basket-added-modal/BasketAddedModal";
import { mergeDateTime } from "./../../../utils/dateUtils";
const CalendarItem = ({ calendar, dateFrom, dateTo, timeFrom, timeTo }) => {
  const { showModal } = useCalendarDetailsModal();
  const [showAddToBasketModal, setShowAddToBasketModal] = useState(false);
  const caldisApi = useCaldisApi();
  const getPhoto = (c) => {
    if (c.Photos?.length > 0) {
      return c.Photos[0].Url;
    } else {
      return "https://placehold.co/800x600";
    }
  };

  const getPhotoAlt = (c) => {
    var alt = "Brak zdjęcia";
    if (c.Photos?.length > 0) {
      alt = c.Photos[0].AlternativeText ?? `Kalenarz ${c.Id}`;
    }
    return alt;
  };

  const renderAvailabilityMessage = (c) => {
    if (c.IsAvailable) {
      return "";
    } else {
      return (
        <div className="unavailable">Niedostępny dla wybranego terminu</div>
      );
    }
  };

  const showCalendarDetails = (calendar) => {
    showModal(calendar.Id, dateFrom, dateTo);
  };

  const addToBasket = async (calendar) => {
    const dateTimeFrom = mergeDateTime(dateFrom, timeFrom);
    const dateTimeTo = mergeDateTime(dateTo, timeTo);
    await caldisApi.basket.addReservation({
      data: {
        IdObject: calendar.Id,
        DateFrom: dateTimeFrom,
        DateTo: dateTimeTo,
      },
      callback: () => {
        setShowAddToBasketModal(true);
      },
    });
  };

  const renderButtons = (calendar) => {
    return (
      <>
        <Button
          className="mx-1 mb-1"
          variant="primary"
          disabled={!calendar.IsAvailable}
          onClick={() => {
            addToBasket(calendar);
          }}
        >
          Rezerwuj
        </Button>

        <Button
          className="mx-1 mb-1"
          variant="outline-primary"
          onClick={() => showCalendarDetails(calendar)}
        >
          Szczegóły
        </Button>

        <BasketAddedModal
          show={showAddToBasketModal}
          onHide={() => {
            setShowAddToBasketModal(false);
          }}
        />
      </>
    );
  };

  return (
    <Col key={calendar.Id} sm={6} md={4}>
      <div className="card-media text-center p-3 pt-5">
        {renderAvailabilityMessage(calendar)}
        <div className="ratio ratio-4x3">
          <img
            className="m-0 img-fluid"
            src={getPhoto(calendar)}
            alt={getPhotoAlt(calendar)}
          />
        </div>

        <div className="card-media-content">
          <div className="card-media-bottom">
            <h2>{calendar.Name}</h2>
            <p>{calendar.Price.PriceGross} PLN/dzień</p>

            {renderButtons(calendar)}
          </div>
        </div>
      </div>
    </Col>
  );
};

CalendarItem.propTypes = {
  calendar: PropTypes.shape({
    Id: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
    Price: PropTypes.shape({
      PriceGross: PropTypes.number.isRequired,
    }).isRequired,
    Photos: PropTypes.arrayOf(
      PropTypes.shape({
        Url: PropTypes.string.isRequired,
      })
    ),
    IsAvailable: PropTypes.bool.isRequired,
  }).isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  timeFrom: PropTypes.string.isRequired,
  timeTo: PropTypes.string.isRequired,
};

export default CalendarItem;
