// src/utils/dateUtils.js
import moment from 'moment';

/**
 * Parses a date string in the format '/Date(1717830000000)/' and returns a formatted date string.
 * @param {string} dateString - The date string to parse.
 * @param {string} dateFormat - The desired format of the output date string.
 * @returns {string} The formatted date string.
 */
export const parseDate = (dateString) => {
  const timestampMatch = dateString.match(/\/Date\((\d+)\)\//);

  if (timestampMatch) {
    const timestamp = parseInt(timestampMatch[1], 10);
    return new Date(timestamp);
  }

  throw new Error('Invalid date format');
};

export const formatDate = (date, dateFormat = 'YYYY-MM-DD') => {
    return moment(date).format(dateFormat);
};

export const mergeDateTime = (dateString, timeString) => {
  const date = new Date(dateString);
  const [hours, minutes] = timeString.split(':').map(Number);
  
  date.setHours(hours, minutes, 0, 0); // Set the time on the date object

  return date;
};

export const toUTCDate = (d) => {
  return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()));
}

