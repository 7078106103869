import React from "react";
const PhotoEmpty = () => {
    return (
        <img
            src="/img/no-photo-800x600.webp"
            alt="Brak zdjęcia"
            className="img-fluid m-0"
            width="800"
            height="600"
        />
    );
};

export default PhotoEmpty;