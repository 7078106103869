import React from "react";
import PropTypes from "prop-types";

const CalendarPhoto = ({ photo, index, onPhotoClick }) => {
    return (
        <>
            <a onClick={() => onPhotoClick(index)}>
                <img
                    className="img-fluid"
                    src={photo.Url}
                    height="800"
                    width="533"
                    alt={photo.AlternativeText ?? `Kalendarz ${index}`}
                />
            </a>
        </>
    );
};

CalendarPhoto.propTypes = {
    photo: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onPhotoClick: PropTypes.func.isRequired,
};

export default CalendarPhoto;
