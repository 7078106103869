import React from "react";
import PropTypes from "prop-types";
import PhotoEmpty from "./NoPhoto";


const Photo = ({src, alt}) => {

    if (src == null) {
        return <PhotoEmpty />;
    }
    
    return (
        <img
            className="img-fluid m-0"
            src={src}
            alt={alt}
        />
    );
};
Photo.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
};

export default Photo;