import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ToastComponent from './toast';

const ToastContext = createContext();

export const useToast = () => {
    return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const showToast = (message, background) => {
        const id = Math.random().toString(36).substring(7);
        setToasts([...toasts, { id, message, background }]);
    };

    const hideToast = (id) => {
        setToasts(toasts.filter(toast => toast.id !== id));
    };

    return (
        <ToastContext.Provider value={{ showToast, hideToast }}>
            {children}
            <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1070 }}>
                {toasts.map((toast) => (
                    <ToastComponent
                        key={toast.id}
                        background={toast.background}
                        message={toast.message}
                        onClose={() => hideToast(toast.id)}
                    />
                ))}
            </div>
        </ToastContext.Provider>
    );
};

ToastProvider.propTypes = {
    children: PropTypes.node.isRequired,
};