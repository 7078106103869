import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import useCaldisApi from "./../../hooks/useCalidsApi";

import OrderPersonalInfo from "./OrderPersonalInfo";
import OrderPaymentInfo from "./OrderPaymentInfo";
import config, { routes } from "./../../Config";
import { useToast } from "../app/toast/toast-context";

const OrderModal = ({ basket }) => {
  const caldisApi = useCaldisApi();
  const { showToast } = useToast();

  const sumBasket = () => {
    let sum = 0;
    basket.Organizations[0].Reservations.forEach((r) => {
      sum += r.SumGross;
    });
    return sum;
  };

  const sumDeposit = () => {
    let sum = 0;
    basket.Organizations[0].Reservations.forEach((r) => {
      sum += r.Deposit;
    });
    return sum;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      TotalPriceGross: sumBasket().toFixed(2),
      TotalDeposit: sumDeposit().toFixed(2),
    },
  });

  const [showDetailsModal, setshowDetailsModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [paymentUrl, setPaymentUrl] = useState(null);

  const handleClose = () => setshowDetailsModal(false);
  const handleShow = () => setshowDetailsModal(true);
  const handleConfirmationClose = () => setShowConfirmationModal(false);
  const handleDetailsClick = () => {
    location.href = routes.order.replace(":orderId", orderId);
  };
  const handlePayButtonClick = () => {
    location.href = paymentUrl;
  };

  const [additionalFields, setAdditionalFields] = useState([]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      await caldisApi.basket.orderConfirm({
        data: {
          ...data,
          urlReturn: `${config.app.rootUrl}${config.przelewy24.urlReturn}`,
        },
        callback: (response) => {
          setshowDetailsModal(false);

          if (response.PaymentUrl) {
            setPaymentUrl(response.PaymentUrl);
          } else {
            setOrderId(response.OrderId);
          }

          setShowConfirmationModal(true);
        },
      });
    } catch (ex) {
      if (ex.ErrorType === "Exception_Validation") {
        showToast(ex.ValidationErrors.CustomValidationSummary, "danger");
      } else {
        throw ex;
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchBookingAdditionalFields = async () => {
    await caldisApi.organization.getBookingAdditionalFields({
      callback: (response) => {
        setAdditionalFields(response);
      },
    });
  };

  useEffect(() => {
    fetchBookingAdditionalFields();
  }, []);

  const renderConfirmationModal = () => {
    if (paymentUrl) {
      return (
        <Modal show={showConfirmationModal}>
          <Modal.Header>
            <Modal.Title>Dokonano rezerwacji</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Dziękujemy za złożenie rezerwacji. </p>

            <p className="fw-bold">
              Opłać zamówienie w ciągu 15 minut, w przeciwnym razie zostanie ono
              automatycznie anulowane.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handlePayButtonClick}>
              Zapłać teraz
            </Button>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return (
        <Modal show={showConfirmationModal} onHide={handleConfirmationClose}>
          <Modal.Header closeButton>
            <Modal.Title>Dokonano rezerwacji</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Dziękujemy za dokonanie rezerwacji. Do zobaczenia wkrótce.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleDetailsClick}>
              Szczegóły
            </Button>
            <Button variant="light" onClick={handleConfirmationClose}>
              Zamknij
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  };

  return (
    <>
      <div className="text-center">
        <Button variant="primary" onClick={handleShow}>
          Zarezerwuj teraz
        </Button>
      </div>

      <Modal show={showDetailsModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Rezerwacja</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div id="basket-order">
              <input
                type="hidden"
                name="CustomValidationSummary"
                {...register("CustomValidationSummary")}
              />

              <h3 className="fs-20px">Twoje dane</h3>
              <OrderPersonalInfo
                register={register}
                additionalFields={additionalFields}
                errors={errors}
              />

              <h3 className="fs-20px">Płatność</h3>
              <OrderPaymentInfo register={register} errors={errors} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Rezerwuję..." : "Zarezerwuj"}
            </Button>
            <Button variant="light" onClick={handleClose}>
              Zamknij
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {renderConfirmationModal()}
    </>
  );
};

OrderModal.propTypes = {
  basket: PropTypes.object.isRequired,
};

export default OrderModal;
