import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import useCaldisApi from "../../hooks/useCalidsApi";
import AvailabilityWidget from "./calendar-widget";
import CalendarPhotoGallery from "../photo-gallery/CalendarPhotoGallery";

const CalendarDetailsModal = ({
    show,
    calendarId,
    dateFrom,
    dateTo,
    handleClose,
}) => {
    const [calendar, setCalendar] = useState(null);

    const caldisApi = useCaldisApi();

    useEffect(() => {
        if (calendarId) {
            const fetchCalendars = async () => {
                await caldisApi.getCalendar({
                    data: {
                        Id: calendarId,
                        DateFrom: dateFrom,
                        DateTo: dateTo,
                    },
                    callback: (response) => {
                        setCalendar(response);
                    },
                });
            };

            fetchCalendars();
        }
    }, [calendarId]);

    if (!calendar) return null;

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{calendar.Name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col-xl-8">
                                <CalendarPhotoGallery calendar={calendar} />

                                <h2 className="fs-20px mb-2">Szczegóły</h2>

                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <ul className="list-group">
                                            <li className="list-group-item fw-bold bg-primary text-white">
                                                Informacje ogólne
                                            </li>
                                            {calendar.Options.map((option, index) => (
                                                <li key={index} className="list-group-item">
                                                    {option}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    {calendar.Properties.map((property) => (
                                        <div key={property.TypeText} className="col-md-6 mb-3">
                                            <ul className="list-group">
                                                <li className="list-group-item fw-bold bg-primary text-white">
                                                    {property.TypeTranslated}
                                                </li>
                                                {property.Values.map((value) => (
                                                    <li key={value} className="list-group-item">
                                                        {value}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: calendar.Description,
                                    }}
                                />
                            </div>
                            <div className="col-xl-4">
                                <h2 className="fs-20px my-2">Dostępność</h2>
                                <AvailabilityWidget calendarId={calendar.Id} />

                                {/* <button
                                    type="button"
                                    className="btn btn-primary w-100 mt-3"
                                >
                                    Zarezerwuj teraz
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleClose}>
                    Zamknij
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

CalendarDetailsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    calendarId: PropTypes.string,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
};

export default CalendarDetailsModal;
