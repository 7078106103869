import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useCaldisApi from "../../../hooks/useCalidsApi";
import VirtualSelect from "../../app/virtual-select/VirtualSelect";

const DynamicFilters = ({onChange}) => {
    const [filters, setFilters] = useState([]);
    const [formFilters, setFormFilters] = useState([]);

    const caldisApi = useCaldisApi();

    
    useEffect(() => {
        const fetchFilters = async () => {
            await caldisApi.filters.getAll({
                callback: (response) => {
                    setFilters(response);

                    var tmpFormFilters = [];

                    response.forEach((filter) => {
                        tmpFormFilters.push({
                            Key: filter.NameText,
                            Value: ""
                        })
                    });
                    setFormFilters(tmpFormFilters);
                },
            });
        };

        fetchFilters();
    }, [caldisApi]);

    const handleSelectChange = (e) => {
        var input = e.target.querySelector("input")

        var tmpFilters = [...formFilters];
        var filter = tmpFilters[tmpFilters.findIndex(f => f.Key === input.name)];
        filter.Value = input.value;

        setFormFilters(tmpFilters);
        onChange(tmpFilters);
    }

    const renderFilters = (filters) => {
        return filters.map((filter, index) => {
            if (filter.TypeText === "Multiselect") {
                const options = filter.Values.map((value) => ({
                    label: value,
                    value: value,
                }));

                return (
                    <div className="col-sm-6 col-xl-3" data-filter key={index}>
                        <div className="mb-1 form-group form-group-filter">
                            <label htmlFor={filter.NameText}>{filter.NameTranslated}</label>
                            <div className="vscomp-ele-parent-w100">
                                <VirtualSelect
                                    name={filter.NameText}
                                    options={options}
                                    onChange={handleSelectChange}
                                    search={filter.Values.length > 10}
                                    multiple={true}
                                />
                            </div>
                        </div>
                    </div>
                );
            } else {
                console.warn("Not supported filter type");
                return null;
            }
        });
    };

    return <div className="row g-1">{renderFilters(filters)}</div>;
};

DynamicFilters.propTypes = {
    onChange: PropTypes.func.isRequired
};

export default DynamicFilters;
